import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthLayoutComponent } from './shared-component/auth-layout/auth-layout.component'
import { PublicLayoutComponent } from './shared-component/public-layout/public-layout.component';
import { BlankComponent } from './shared-component/blank/blank.component';
import { NotFoundComponent } from './shared-component/errors/not-found/not-found.component';
import { ErrorComponent } from './shared-component/errors/error/error.component';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
    {
        path: '', component: AuthLayoutComponent, children: [
            //{ path: 'constant', loadChildren: () => import('./pages/card/constant/constant.module').then(m => m.ConstantModule), data: { breadcrumb: 'Constant' } },
            { path: 'exams', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Exams' } },
            { path: 'surveys', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Surveys', ExamType: 3 } },
            { path: 'evaluations', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Evaluations', ExamType: 4 } },
            { path: 'mocks', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Mocks', ExamType: 5 } },
            { path: 'setups', loadChildren: () => import('./pages/Setups/setups.module').then(m => m.SetupsModule), data: { breadcrumb: 'Setups' } },
            { path: 'reports', loadChildren: () => import('./pages/Reports/reports.module').then(m => m.ReportsModule), data: { breadcrumb: 'Reports' } },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
        ],
        canActivate: [AuthGuard]
    },

    {
        path: '', component: PublicLayoutComponent, children: [
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'login', loadChildren: () => import('./shared-component/login/login.module').then(m => m.LoginModule) },
            { path: 'register', loadChildren: () => import('./shared-component/register/register.module').then(m => m.RegisterModule) },
            { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
        ]
    },

    { path: '**', component: NotFoundComponent }
];


@NgModule({
    imports: [
        //RouterModule.forRoot(routes)
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }